export const dataTestIdSelector = (value: string) => `[data-testid="${value}"]`;

export const cypressDataTestIdSelector = (value: string) => `[data-cy="${value}"]`;

export const dataTestIds = {
    login: { emailInput: "email-input", continueButton: "continue-button", passwordInput: "password-input", submitButton: "submit-button" },
    dashboard: { createMapButton: "create-new-map-button" },
    library: { tileNode: (name: string) => `library-tile-node-${name}` },
    sidebar: {
        createNewLayerButton: "new-layer-button",
        createCustomLayerButton: "custom-layer-button",
        importLayerButton: "import-layer",
        layerVisibilityIcon: (name: string) => `visibility-icon-${name}`,
        sidebarNode: (name: string) => `sidebar-node-${name}`,
        nodeSelected: (name: string) => `selected-${name}`,
        expandNode: (name: string) => `expand-${name}`,
        layerPreview: (name: string) => `layer-preview-${name}`,
    },
    editLayer: {
        preview: "layer-preview",
        lineWidth: "line-width",
        arrowSpacing: "arrow-spacing",
        arrowsContainer: "arrows-container",
        incrementalValueInputIncrease: (id: string) => (id ? `incremental-value-increment-${id}` : "incremental-value-increment"),
        optionsContainer: (name: string) => `style-container-${name}`,
        definedValue: (name: string) => `defined-value-${name}`,
    },
    modals: { createMap: "create-map", createCustomLayerButton: "create-custom-layer-button", nameOfLayerInput: "name-of-layer-input", cancelButton: "cancel-button" },
    libraryTesting: {
        container: "library-testing-container",
        viewTypeSelector: "library-testing-view-type",
        viewTypeList: "library-test-list",
        viewTypeTile: "library-test-tile",
        nodeId: (type: string, index: number) => `${type}-${index}`,
    },
    libraryNode: { chevron: (nodeId: string) => `expand-node-id-${nodeId}`, checkbox: (nodeId: string) => `check-node-id-${nodeId}` },
} as const;

export const mapboxMapSelector = "canvas.mapboxgl-canvas";

export const virtualScrollingContainerSelector = `[data-test-id="virtuoso-scroller"]`;
